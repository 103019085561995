<template>
  <div class="page-box" ref="content">
    <div
      class="page-box-con">
      <div
        class="main-left"
      >
        <el-image fit="cover" :src="logoImg" class="index-logo"></el-image>
        <ul class="menu-list" ref="menuList">
          <li
            class="menu-item"
            :class="{ active: item.status }"
            v-for="(item, index) in menuData"
            :key="`menu${index}`"
            @click="selectMenu(item, index)"
          >
            <el-image
              class="menu-icon"
              fit="cover"
              :src="item.iconWhite"
              v-show="item.status"
            ></el-image>
            <el-image
              class="menu-icon"
              fit="cover"
              :src="item.iconGary"
              v-show="!item.status"
            ></el-image>
            <span>{{ item.text }}</span>
          </li>
        </ul>
      </div>
      <div
        class="main-right"
        ref="mainRight"
      >
        <div class="right-menu"></div>
        <div class="right-con">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "indexPage",
  data() {
    return {
      logoImg: require("@/assets/image/logo.png"),
      userIcon: require("@/assets/image/icon/userIcon.png"),
      menuData: [
        {
          text: "首页",
          iconGary: require("@/assets/image/icon/menu_icon0_0.png"),
          iconWhite: require("@/assets/image/icon/menu_icon0_1.png"),
          url: "/",
          status: true,
        },
        {
          text: "图书馆",
          iconGary: require("@/assets/image/icon/menu_icon1_0.png"),
          iconWhite: require("@/assets/image/icon/menu_icon1_1.png"),
          url: "/library",
          status: false,
        },
        {
          text: "错题本",
          iconGary: require("@/assets/image/icon/menu_icon2_0.png"),
          iconWhite: require("@/assets/image/icon/menu_icon2_1.png"),
          url: "/errorBook",
          status: false,
        },
        {
          text: "学习中心",
          iconGary: require("@/assets/image/icon/menu_icon3_0.png"),
          iconWhite: require("@/assets/image/icon/menu_icon3_1.png"),
          url: "/userCenter",
          status: false,
        },
      ],
      scaleNum: 1,
      screenWidth: 1920,
      screenHeight: 1080,
      marginNum: 50,
      mainRightWidth:0
    };
  },
  watch: {},
  created() {
    let idx = this.$route.query.indexActive||0;
    this.setActive(idx);
    this.initScale();
  },
  mounted() {
    
    window.addEventListener("resize", () => {
      this.initScale();
    });
    this.$eventBus.$on("backIndex", () => {
      this.setActive(0);
    });
  },
  destroyed(){
    this.$eventBus.$off("backIndex")
  },
  methods: {
    initScale() {
      let viewHeight = document.body.offsetHeight;
      let viewWidth = document.body.offsetWidth;
      this.scaleNum = viewHeight / 1080;
      this.marginNum = 50 * this.scaleNum;
      this.screenWidth = viewWidth / this.scaleNum;
      this.screenHeight = viewHeight / this.scaleNum;

      this.mainRightWidth = this.screenWidth - 336*this.scaleNum;
    },
    selectMenu(item, idx) {
      this.setActive(idx);
      this.$router.replace({
        path: item.url,
        query:{
          indexActive:idx
        }
      });
      this.$eventBus.$emit("changeIndeMenu");
    },
    setActive(idx) {
      this.menuData.forEach((item, index) => {
        item.status = false;
        if (index == idx) {
          item.status = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  display: flex;
  justify-content: flex-start;
  transform-origin: 0 0;
  .page-box-con {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .main-left {
    display: flex;
    flex-direction: column;
    width: 2.54rem;
    min-width: 2.54rem;
    margin-right: .32rem;
    transform-origin: left top;
    color: #fff;
    margin: .5rem .32rem .5rem .5rem;
    .index-logo {
      width: 6.7rem;
      height: .65rem;
      min-height: .65rem;
      margin-bottom: .67rem;
    }
    .menu-list {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      background: #ffffff;
      border-radius: .20rem;
      width: 100%;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 1.60rem;
        font-size: .24rem;
        color: #333;
        cursor: pointer;
        .menu-icon {
          margin-bottom: .20rem;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .active {
        color: #fff;
        background: #ffa800;
        border-radius: .10rem;
      }
    }
  }
  .main-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    transform-origin: left top;
    overflow: hidden;
    margin: .5rem .5rem .5rem 0;
    .right-menu {
      height: .81rem;
      min-height: .81rem;
      margin-bottom: .47rem;
    }
    .right-con {
      flex: 1;
      background: #ffffff;
      border-radius: .20rem;
      overflow: hidden;
    }
  }
}
</style>