import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import { getToken} from '@/utils/token'
function getComplatedStatus(){
  return window.localStorage.getItem('complatedStatus')
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: index,
    children: [
      {
        path: '/',
        name: 'mainIndex',
        component: () => import('@/views/index/mainIndex.vue')
      },
      {
        path: '/library',
        name: 'library',
        component: () => import('@/views/library/index.vue')
      },
      {
        path: '/errorBook',
        name: 'errorBook',
        component: () => import('@/views/errorBook/index.vue')
      },

      {
        path: '/userCenter',
        component: () => import('@/views/userCenter/index.vue'),
        children: [
          {
            path: '/',
            name: 'version',
            component: () => import('@/views/userCenter/version.vue')
          },
          {
            path: '/history',
            name: 'history',
            component: () => import('@/views/userCenter/history.vue')
          },
          {
            path: '/userAudio',
            name: 'userAudio',
            component: () => import('@/views/userCenter/userAudio.vue')
          },
          {
            path: '/userInfs',
            name: 'userInfs',
            component: () => import('@/views/userCenter/userInfs.vue')
          },
          {
            path: '/changePassword',
            name: 'changePassword',
            component: () => import('@/views/userCenter/changePassword.vue')
          },
          {
            path: '/afterSale',
            name: 'afterSale',
            component: () => import('@/views/userCenter/afterSale.vue')
          },
          {
            path: '/barEchart',
            name: 'barEchart',
            component: () => import('@/views/userCenter/barEchart.vue')
          },
          {
            path: '/ranking',
            name: 'ranking',
            component: () => import('@/views/userCenter/ranking.vue')
          }
        ]
      },


    ]
  },
	
  {
    path: '/classIndex',
    name:'classIndex',
    component: () => import('@/views/class/index.vue'),
    children: [{
      path: '/',
      name: 'zsqj',
      component: () => import('@/views/class/zsqj.vue'),
    },
    {
      path: '/jzjx',
      name: 'jzjx',
      component: () => import('@/views/class/jzjx.vue')
    },
    {
      path: '/zxpy',
      name: 'zxpy',
      component: () => import('@/views/class/zxpy.vue')
    },
    {
      path: '/jzbk',
      name: 'jzbk',
      component: () => import('@/views/class/jzbk.vue')
    },
    {
      path: '/sytj',
      name: 'sytj',
      component: () => import('@/views/class/sytj.vue')
    },
    {
      path: '/chapter',
      name: 'chapter',
      component: () => import('@/views/class/chapter.vue'),
    },
    {
      path:'/innerChapter',
      name:'innerChapter',
      component:()=> import('@/views/class/innerChapter.vue')
    }
    ]
  },
  {
    path: '/zhenTi',
    component: () => import('@/views/zhenTi/index.vue'),
    children: [
      {
        path: '/',
        name: 'zhenTiMenu',
        component: () => import('@/views/zhenTi/zhenTiMenu.vue')
      },


    ]

  },
  {
    path: '/zhenTiContent',
    component: () => import('@/views/zhenTi/zhenTiContent.vue'),
    children: [{
      path: '/',
      name: 'zhenTiQuestion',
      component: () => import('@/views/zhenTi/zhenTiQuestion.vue')
    }, {
      path: '/zhenTiAnswer',
      name: 'zhenTiAnswer',
      component: () => import('@/views/zhenTi/zhenTiAnswer.vue')
    }]
  },
  {
    path: '/famousRead',
    component: () => import('@/views/moudle/index.vue'),
    children: [{
      path: '/',
      name: 'famousRead',
      component: () => import('@/views/moudle/famousRead.vue')
    },

    {
      path: '/author',
      name: 'author',
      component: () => import('@/views/moudle/author.vue')
    },
    // {
    //   path: '/contentQuestion',
    //   name: 'contentQuestion',
    //   component: () => import('@/views/moudle/contentQuestion.vue')
    // },
     {
      path: '/childContent',
      name: 'childContent',
      component: () => import('@/views/moudle/childContent.vue'),
      children:[
        {
          path: '/contentPage',
          name: 'contentPage',
          component: () => import('@/views/moudle/contentPage.vue'),
        },
        {
          path: '/questionPage',
          name: 'questionPage',
          component: () => import('@/views/moudle/questionPage.vue'),
        }
      ]
    },

    

    {
      path: '/completeSetQuestion',
      name: 'completeSetQuestion',
      component: () => import('@/views/moudle/completeSetQuestion.vue')
    },

    {
      path: '/videoPage',
      name: 'videoPage',
      component: () => import('@/views/moudle/videoPage.vue')
    },
    

    ]
  },
  {

    path: '/wordsBreakThrough',
    name: 'wordsBreakThrough',
    component: () => import('@/views/moudle/wordsBreakThrough.vue')
  },
  {

    path: '/wordsBreakThroughUnit',
    name: 'wordsBreakThroughUnit',
    component: () => import('@/views/moudle/wordsBreakThroughUnit.vue')
  },

  
  {

    path: '/bookDetail',
    name: 'bookDetail',
    component: () => import('@/views/library/bookDetail.vue')
  },
//在线答疑
  {
    path: "/answerQuestions",
    name: "answerQuestions",
    component: () => import("@/views/answerQuestions/index.vue"),
  },
  {
    path: '/errorView',
    name: 'errorView',
    component: () => import('@/views/moudle/errorView.vue')
  },
  
  {

    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {

    path: '/complateInfs',
    name: 'complateInfs',
    component: () => import('@/views/complateInfs.vue')
  },

  {

    path: '/sigin',
    name: 'sigin',
    component: () => import('@/views/sigin.vue')
  },

  {

    path: '*',
    name: 'errorPage',
    component: () => import('@/views/errorPage.vue')
  },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  }
})
router.afterEach((to, from) => {
  history.pushState(null, null, location.protocol + '//' + location.host + '/#' + to.fullPath)
})

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = getToken()
  if (to.path != '/login' && to.path != '/sigin') {
    //通过查看token判断是否登录
    if (token && token != '') {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login"
      });
      
    }

    if(to.path != '/complateInfs'){
      let isComplated = getComplatedStatus();
      if (isComplated && isComplated != '' && isComplated!=0) {
        next(); //表示已经完成完善信息
      } else {
        //next可以传递一个路由对象作为参数 表示需要跳转到的页面
        next({
          name: "complateInfs"
        });
        
      }
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});


export default router
