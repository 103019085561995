import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer(val) {
    return {
      common: {
        webInfs:val.common.webInfs,
        userInfs: val.common.userInfs,
        unitNav: val.common.unitNav,
        classMask:val.common.classMask,
        moudleNav:val.common.moudleNav,
        questionGroup:val.common.questionGroup,
        classQuery:val.common.classQuery,
        setChapterQuery:val.common.setChapterQuery,
        zhenTiContent:val.common.zhenTiContent,
        subNav:val.common.subNav,
        channelChildren:val.common.channelChildren,
        innerUnitNav:val.common.innerUnitNav,
        innerChapterQuery:val.common.innerChapterQuery,
        chapterQuery:val.common.chapterQuery,
        rememberAcount:val.common.rememberAcount,
        acountInfs:val.common.acountInfs,
        breadCrumbs:val.common.breadCrumbs,
        currentClass:val.common.currentClass,
        channelName:val.common.channelName,
        zhenTiPath:val.common.zhenTiPath,
        menuParmas:val.common.menuParmas,
        moudleName:val.common.moudleName,
        wordsList:val.common.wordsList,
        lianxiData1:val.common.lianxiData1,
        lianxiData2:val.common.lianxiData2,
        scrollDomTop:val.common.scrollDomTop
        
      }

    }
  }
})
const store = new Vuex.Store({
  strict: false,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
  },
  plugins: [vuexLocal.plugin]

})

export default store;
