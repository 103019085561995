const state = {
    tabActive: 0,
    detailActive: 0,
    userInfs: {},
    refreshToken: false,
    flowDetail: {},
    unitNav: [],
    classMask: '',
    moudleNav: [],
    questionGroup: [],
    classQuery: {},
    zhenTiContent: {},
    subNav:[],
    chapterQuery:{},
    webInfs:{},
    siteurl:'',
    channelChildren:[],
    scrollDomTop:{},
    innerUnitNav:[],
    innerChapterQuery:{},
    isFullScreen:false,
    rememberAcount:false,
    acountInfs:{
        account:"",
        password:""
    },
    breadCrumbs:[],
    currentClass:0,
    channelName:'',
    zhenTiPath:{},
    menuParmas:{},
    moudleName:'',

    wordsList:[],
    lianxiData1:[],
    lianxiData2:[],
    tingliAudio:''
    
}
const getters = {
    getTabActive(state) {
        return state.tabActive
    },
    getUserInfs(state) {
        return state.userInfs
    },
    getRefreshToken(state) {
        return state.refreshToken
    },
    getDetailActive(state) {
        return state.detailActive
    },
    getFlowDetail(state) {
        return state.flowDetail
    },
    getUnitNav(state) {
        return state.unitNav
    },
    getClassMask(state) {
        return state.classMask
    },
    getMoudleNav(state) {
        return state.moudleNav
    },
    getQuestionGroup(state) {
        return state.questionGroup
    },
    getClassQuery(state) {
        return state.classQuery
    },
    getZhenTiContent(state) {
        return state.zhenTiContent
    },
    getSubNav(state){
        return state.subNav
    },
    getChapterQuery(state){
        return state.chapterQuery
    },
    getWebInfs(state) {
        return state.webInfs
    },
    getSiteurl(state) {
        return state.siteurl
    },
    getChannelChildren(state){
        return state.channelChildren
    },
    getScrollDomTop(state){
        return state.scrollDomTop
    },
    getInnerUnitNav(state){
        return state.innerUnitNav
    },
    getInnerChapterQuery(state){
        return state.innerChapterQuery
    },
    getFullScreen(state){
        return state.isFullScreen
    },
    getRememberAcount(state){
        return state.rememberAcount
    },
    getAcountInfs(state){
        return state.acountInfs
    },
    getBreadCrumbs(state){
        return state.breadCrumbs
    },
    getCurrentClass(state){
        return state.currentClass
    },
    getChannelName(state){
        return state.channelName
    },
    getZhenTiPath(state){
        return state.zhenTiPath
    },
    getMenuParmas(state){
        return state.menuParmas
    },
    getMoudleName(state){
        return state.moudleName
    },
    getWordsList(state){
        return state.wordsList
    },
    getLianxiData1(state){
        return state.lianxiData1
    },
    getLianxiData2(state){
        return state.lianxiData2
    },
    getTingliAudio(state){
        return state.tingliAudio
    }

}
const actions = {

}
const mutations = {
    setTabActive(state, data) {
        state.tabActive = data
    },
    setUserInfs(state, data) {
        state.userInfs = data
    },
    setRefreshToken(state, data) {
        state.refreshToken = data
    },
    setDetailActive(state, data) {
        state.detailActive = data
    },

    setFlowDetail(state, data) {
        state.flowDetail = data
    },
    setUnitNav(state, data) {
        state.unitNav = data
    },
    setClassMask(state, data) {
        state.classMask = data
    },
    setMoudleNav(state, data) {
        state.moudleNav = data
    },
    setQuestionGroup(state, data) {
        state.questionGroup = data
    },
    setClassQuery(state, data) {
        state.classQuery = data
    },
    setZhenTiContent(state, data) {
        state.zhenTiContent = data
    },
    setSubNav(state,data){
        state.subNav = data
    },
    setChapterQuery(state,data){
        state.chapterQuery = data
    },

    setWebInfs(state, data) {
        state.webInfs = data
    },
    setSiteurl(state, data) {
        state.siteurl = data
    },
    setChannelChildren(state,data){
        state.channelChildren = data
    },
    setScrollDomTop(state,data){
        state.scrollDomTop = data
    },
    setInnerUnitNav(state,data){
        state.innerUnitNav = data
    },
    setInnerChapterQuery(state,data){
        state.innerChapterQuery =data
    },
    setFullScreen(state,data){
        state.isFullScreen = data
    },
    setRememberAcount(state,data){
        state.rememberAcount=data
    },
    setAcountInfs(state,data){
        state.acountInfs=data
    },
    setBreadCrumbs(state,data){
        state.breadCrumbs=data
    },
    setCurrentClass(state,data){
        state.currentClass = data
    },
    setChannelName(state,data){
        state.channelName = data
    },
    setZhenTiPath(state,data){
        state.zhenTiPath = data
    },
    setMenuParmas(state,data){
        state.menuParmas = data
    },
    setMoudleName(state,data){
        state.moudleName=data
    },
    setWordsList(state,data){
        state.wordsList=data
    },
    setLianxiData1(state,data){
        state.lianxiData1=data
    },
    setLianxiData2(state,data){
        state.lianxiData2=data
    },
    setTingliAudio(state, data){
        state.tingliAudio = data
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}