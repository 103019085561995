<template>
	<div id="app" :style="`backgroundImage:url(${globalBg})`">
		<router-view />
		<!-- <div class="webInfs">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >京ICP备2022024380号</a
      >
      Copyright ©2021 京点未来教育研究院 版权所有
    </div> -->

		<div class="heard-btn" v-if="isIndex">
			<!-- <div class="full-icon cup" @click="pagedyFn">
				在线
				<br />
				答疑
			</div> -->
			<div
				class="full-icon cup"
				:style="`backgroundImage:url(${fullFlag ? exitFull : fsHandle})`"
				@click="fullScreenAction"
			></div>

			<div class="full-icon cup" :style="`backgroundImage:url(${exitIcon})`" @click="exitAction"></div>
		</div>
		<audio
			:src="cardVoice"
			controlslist="nodownload"
			controls="controls"
			ref="cardVoice"
			class="card-vioce"
		></audio>
	</div>
</template>

<script>
import { getToken, setToken } from '@/utils/token';
import { mapGetters } from 'vuex';
import { fullScreen, exitScreen, fullStatus, setSourceUrl } from '@/utils/public.js';
export default {
	name: 'App',
	computed: {
		...mapGetters({
			isFullScreen: 'common/getFullScreen'
		})
	},
	watch: {
		$route: function (val) {
			let dom = document.getElementsByClassName('el-message')[0];
			if (dom) {
				dom.remove();
			}
			if (this.showFullScreen.includes(val.name)) {
				this.isIndex = true;
			} else {
				this.isIndex = false;
			}

			if (this.hideBackPrev.includes(val.name)) {
				this.showBack = true;
			} else {
				this.showBack = false;
			}
		}
	},
	data() {
		return {
			excludeList: ['classIndex'],
			globalBg: require('@/assets/image/bg.png'),
			timmer: null,
			scaleNum: 1,
			showFullScreen: [
				'mainIndex',
				'library',
				'errorBook',
				'history',
				'userAudio',
				'version',
				'userInfs',
				'changePassword',
				'afterSale'
			],
			hideBackPrev: [
				'mainIndex',
				'library',
				'errorBook',
				'history',
				'login',
				'userAudio',
				'version',
				'userInfs',
				'changePassword',
				'afterSale'
			],
			excludePage: ['errorView'],
			isIndex: false,
			showBack: false,
			fullFlag: false,

			cardVoice: require('@/assets/audio/cardVoice.mp3'),
			cardAudio: null,
			testMath:
				'{33}_{44\\sqrt[{2}] {44\\iint  {444\\sum ^{1}_{3} {2}}}}\\iiint  {000\\left \\{  {1000} \\right \\} }'
		};
	},
	created() {
		// var is360 = _mime("type", "application/vnd.chromium.remoting-viewer");
		// if (isChrome() && !is360) {
		//   this.$message({
		//     type: "warning",
		//     message: "为了有更好的使用体验，请使用360浏览器！",
		//     duration:0,
		//     showClose:true
		//   });
		// }
		function isChrome() {
			var ua = navigator.userAgent.toLowerCase();

			return ua.indexOf('chrome') > 1;
		}
		function _mime(option, value) {
			var mimeTypes = navigator.mimeTypes;
			for (var mt in mimeTypes) {
				if (mimeTypes[mt][option] == value) {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.fullFlag = !this.fullFlag;
		});
		this.timmer = setTimeout(() => {
			let path = this.$route.path;
			let token = this.getToken();
			if (path != '/login') {
				if (token && token != '') {
					this.checkToken();
				}
			}
		}, 300);

		this.cardAudio = this.$refs['cardVoice'];
		this.$eventBus.$on('clickMenu', () => {
			this.cardAudio.play();
		});
		window.addEventListener('dragstart', (e) => {
			let localName = e.target.localName;
			if (localName == 'img') {
				e.preventDefault();
			}
		});
	},
	destroyed() {
		clearTimeout(this.timmer);
		window.removeEventListener('popstate', this.goBack, false);
	},
	methods: {
		getToken,
		setToken,
		fullScreen,
		exitScreen,
		fullStatus,
		setSourceUrl,
		imgdragstart() {
			return false;
		},
		fullScreenAction() {
			if (this.fullFlag) {
				this.exitScreen(() => {
					this.$store.commit('common/setFullScreen', false);
				});
			} else {
				this.fullScreen(() => {
					this.$store.commit('common/setFullScreen', true);
				});
			}
		},

		checkToken() {
			this.$api.checkToken().then((res) => {
				let expiresTime = res.data.expires_in;
				if (expiresTime < 60000) {
					this.$store.commit('common/setRefreshToken', true);
					this.refreshToken();
				} else {
					this.$store.commit('common/setRefreshToken', false);
				}
			});
		},
		refreshToken() {
			this.$api.refreshToken().then((res) => {
				this.setToken(res.data.token);
				this.$store.commit('common/setRefreshToken', false);
			});
		},
		exitAction() {
			this.$confirm();

			let that = this;
			that.$confirm('确定要退出吗？', {
				showClose: false,
				callback: function (val) {
					if (val == 'confirm') {
						that.$api.userLogout().then((res) => {
							if (res.code) {
								window.localStorage.setItem('Authorization', '');
								window.localStorage.setItem('complatedStatus', '');
								window.localStorage.setItem('layerStatus', '');
								window.location.reload();
							}
						});
					}
				}
			});
		},
		pagedyFn() {
			this.$router.replace({
				path: '/answerQuestions'
			});
		}
	}
};
</script>

<style lang="scss">
@import 'assets/css/iconfont.css';
@import 'assets/css/baseStyle.scss';
.cimg {
	// display: inline-block;
	// padding-bottom: 0.8rem;
	img {
		// transform: translateY(43%);
		vertical-align: middle;
	}
}
.question-scroll-box {
	height: 100%;
}
.custom-scroll {
	height: 100%;
	overflow: auto;
	text-align: justify;
	justify-content: space-between;
}

.webInfs {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0.15rem;
	font-size: 0.18rem;
	color: #fff;
	text-align: center;
	a {
		color: #fff;
		text-decoration: none;
	}
}
.math-formula {
	font-size: 18px !important;
	font-family: MathJax_Main !important;
}
.math-ba {
	position: relative;
	&::before {
		position: absolute;
		left: 0;
		right: 0;
		top: 0.07rem;
		content: '';
		height: 0.01rem;
		background: #333;
	}
}
.el-message-box__message p {
	font-size: 0.2rem;
}
.el-message-box__btns {
	.el-button {
		font-size: 0.16rem;
		font-weight: normal;
	}
}
.el-image__inner {
	image-rendering: -webkit-optimize-contrast;
}
.el-message__icon {
	font-size: 0.4rem;
}
.el-button--primary {
	background-color: #1e9e85 !important;
	border-color: #1e9e85 !important;
	&:hover {
		opacity: 0.8;
	}
}
.el-message-box__message {
	font-size: 0.16rem;
	padding: 0.2rem 0;
	text-align: center;
}

.back-prev-btn {
	position: fixed;
	z-index: 1000;
	width: 0.64rem;
	height: 0.64rem;
	background-size: cover;
	cursor: pointer;
}

.children-router {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
}
.bscroll-indicator {
	background: #dcdcdc !important;
}
.heard-btn {
	position: fixed;
	right: 0;
	top: 0;
	padding: 0.5rem;
	z-index: 1000;
	display: flex;
	align-items: center;
	transform-origin: right top;
	.full-icon {
		width: 0.64rem;
		height: 0.64rem;
		margin-left: 0.1rem;
		background-size: cover;
		border-radius: 50%;
		background-color: #fff;
		font-size: 0.16rem;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			opacity: 0.8;
		}
	}
}
</style>
<style lang="scss" scoped>
.card-vioce {
	position: fixed;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}
#app {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	user-select: text;
	transform-origin: center;
}
</style>
