let mixin = {
  computed: {

  },
  watch: {

  },
  data() {
    return {
      fsHandle: require("@/assets/image/icon/fullScreen.png"),
      exitFull: require("@/assets/image/icon/exitFull.png"),
      backPage: require("@/assets/image/icon/backPage.png"),
      exitIcon: require("@/assets/image/icon/exit.png"),
      indexIcon: require("@/assets/image/icon/index.png"),
      isLoading: false,
      isLoading1:false,
      fullscreen:false
    }
  },
  mounted() {
    
  },
  methods: {
    // fullScreenAction(e) {
    //   const element = document.documentElement;
    //   // 如果是全屏状态
    //   if (this.fullscreen) {
    //     // 如果浏览器有这个Function
    //     if (document.exitFullscreen) {
    //       document?.exitFullscreen();
    //     } else if (document.webkitCancelFullScreen) {
    //       document?.webkitCancelFullScreen();
    //     } else if (document.mozCancelFullScreen) {
    //       document?.mozCancelFullScreen();
    //     } else if (document.msExitFullscreen) {
    //       document?.msExitFullscreen();
    //     }
    //   } else {
    //     // 如果浏览器有这个Function
    //     if (element.requestFullscreen) {
    //       element.requestFullscreen();
    //     } else if (element.webkitRequestFullScreen) {
    //       element.webkitRequestFullScreen();
    //     } else if (element.mozRequestFullScreen) {
    //       element.mozRequestFullScreen();
    //     } else if (element.msRequestFullscreen) {
    //       element.msRequestFullscreen();
    //     }
    //   }
    //   // 判断全屏状态的变量
    //   this.fullscreen = !this.fullscreen;
    // },

    backPrev() {
      let cpath = this.$route.path;
      if (cpath == '/bookDetail') {
        this.$router.replace({
          path:'/library'
        })
      } else {
        this.$router.go(-1);
        this.$eventBus.$emit("backPervAction")
      }

    },
    
    backIndex(){
      this.$router.replace({
        path:'/'
      })
      this.$eventBus.$emit("backIndex")
    }
  }
}
export default mixin;